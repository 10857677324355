import React from "react";
import Detail from "./../../components/detail";
import { findByPathName } from "./../../utils/helper";
import { getLoanApplicationInfo, getLoanBankInfo, getLoanCkycDetail, getLoanDeductionInfo, getLoanDigiLockerInfo, getLoanDisbursementInfo, getLoanEmandateInfo, getLoanInfo, getLoanKycInfo, getLoanUpiAutoPayInfo, getLoanUserInfo, updatePaymentRecord } from "./../../service/api";
import { convertTimestamptoDate } from "./../../utils/helper";
import {
    Button, Snackbar
} from "@material-ui/core";
import { rolePermission } from "../../common/constants";
import { getLocalStorage } from "../../utils/localStorage";

function UpdatePaymentButton (props) {
    const { loanId } = props;
    const [loading, setLoading] = React.useState(false);
    const [open, setSnackbar] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const handleCallback = (res) => {
        if (res.success) {
            setMessage('Payment records updated successfully');
        } else {
            setMessage(res.message ?? 'some error occured');
        }
        setLoading(false);
        setSnackbar(true);
    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="default"
                disabled={loading}
                onClick={() => {
                    setLoading(true);
                    updatePaymentRecord(loanId, {}, handleCallback)
                }}
                style={{
                    backgroundColor: loading ? 'grey' : "rgb(16,12,109)",
                    color: "white",
                    borderRadius: 5,
                }}
            >UPDATE</Button>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setSnackbar(false)}
                message={message}
                action={null}
            />
        </React.Fragment>
    )
}


class LoanDetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            data: [],
            loanDetails: {},
            loanUserDetails: {},
            loanApplicationDetails: {},
            loanDisbursementDetails: {},
            loanBankDetails: {},
            loanEmandateDetails: {},
            loanKycDetails: {},
            deductionDetails: {},
            loanDigiLockerDetails: {},
            loanUpiAutoPayDetails: {}
        }
    }

    componentDidMount () {
        const { location } = this.props;
        const loanId = findByPathName(location);
        this.callApis(loanId);
    }


    callApis = (id) => {

        const getLoanInfoPromise = new Promise(function (resolve, reject) {
            getLoanInfo(id, {}, res => resolve(res))
        })
        const getLoanUserInfoPromise = new Promise(function (resolve, reject) {
            getLoanUserInfo(id, {}, res => resolve(res))
        })
        const getLoanApplicationInfoPromise = new Promise(function (resolve, reject) {
            getLoanApplicationInfo(id, {}, res => resolve(res))
        })
        const getLoanDisbursementInfoPromise = new Promise(function (resolve, reject) {
            getLoanDisbursementInfo(id, {}, res => resolve(res))
        })
        const getLoanBankInfoPromise = new Promise(function (resolve, reject) {
            getLoanBankInfo(id, {}, res => resolve(res))
        })
        const getLoanEmandateInfoPromise = new Promise(function (resolve, reject) {
            getLoanEmandateInfo(id, {}, res => resolve(res))
        })
        const getLoanKycInfoPromise = new Promise(function (resolve, reject) {
            getLoanKycInfo(id, {}, res => resolve(res))
        })

        const getLoanDeductionInfoPromise = new Promise(function (resolve, reject) {
            getLoanDeductionInfo(id, {}, res => resolve(res))
        })

        const getLoanDigiLockerPromise = new Promise(function (resolve, reject) {
            getLoanDigiLockerInfo(id, {}, res => resolve(res))
        })

        const getLoanUpiAutoPayPromise = new Promise(function (resolve, reject) {
            getLoanUpiAutoPayInfo(id, {}, res => resolve(res))
        })
        const getLoanCkycDetailsPromise = new Promise((resolve, reject) => {
            getLoanCkycDetail(id, (res) => resolve(res))
        })

        Promise.all([
            getLoanInfoPromise,
            getLoanUserInfoPromise,
            getLoanApplicationInfoPromise,
            getLoanDisbursementInfoPromise,
            getLoanBankInfoPromise,
            getLoanEmandateInfoPromise,
            getLoanKycInfoPromise,
            getLoanDeductionInfoPromise,
            getLoanDigiLockerPromise,
            getLoanUpiAutoPayPromise,
            getLoanCkycDetailsPromise,
        ]).then(res => {
            this.setState({
                loanDetails: res[0],
                loanUserDetails: res[1],
                loanApplicationDetails: res[2],
                loanDisbursementDetails: res[3],
                loanBankDetails: res[4],
                loanEmandateDetails: res[5],
                loanKycDetails: res[6],
                deductionDetails: res[7],
                loanDigiLockerDetails: res[8],
                loanUpiAutoPayDetails: res[9],
                loanCkycDetails: res[10],
                id: id
            }, () => this.makeColumns());
        }).catch(err => {
            this.setState({
                hasError: true,
                loading: false,
                id: id
            })
        })
    }

    createEmandateDetail = (dat) => {

        if (!dat) return [];

        const { createdDate, completedDate, status, emandateBankName, emandateAccountNumber, emandateIfscCode, mode, vpa } = dat;
        return [
            { key: "emandate-created-date", label: "CREATED DATE", value: convertTimestamptoDate(createdDate) },
            { key: "emandate-completed-date", label: "COMPLETED DATE", value: convertTimestamptoDate(completedDate) },
            { key: "emandate-status", label: "STATUS", value: status },
            { key: "emandate-bank-name", label: "BANK NAME", value: emandateBankName },
            { key: "emandate-acc-number", label: "ACCOUNT NUMBER", value: emandateAccountNumber },
            { key: "emandate-ifsc-code", label: "IFSC CODE", value: emandateIfscCode },
            { key: "emandate-mode", label: "PAYMENT METHOD", value: mode ?? "-" },
            { key: "emandate-vpa", label: "VPA", value: vpa ?? "-" }
        ]
    }

    createBankDetail = (dat) => {

        if (!dat) return [];

        const { accountNumber, ifscCode, accountHolderName, bankDetails,
            bankDetailsCompleteDate, bankVerificationComment
        } = dat;
        return [
            // { key: "bank-name", label: "BANK NAME", value: bankName },
            { key: "acc-holder-name", label: "ACCOUNT HOLDER NAME", value: accountHolderName },
            { key: "account-number", label: "ACCOUNT NUMBER", value: accountNumber },
            { key: "ifsc-code", label: "IFSC CODE", value: ifscCode },
            { key: "bank-detail", label: "BANK DETAIL", value: bankDetails ? "Yes" : "No" },
            { key: "bank-detail-complete-date", label: "BANK DETAILS COMPLETE DATE", value: convertTimestamptoDate(bankDetailsCompleteDate) },
            { key: "bank-verification-comment", label: "BANK VERIFICATION COMMENT", value: bankVerificationComment },

        ]
    }

    createDeductionDetail = (dat) => {
        if (!dat) return [];

        const { deductionRequestRaised, requestDate, deductionRequestedAmount } = dat;
        return [
            { key: "deduction-request-raised", label: "DEDUCTION REQUEST RAISED", value: deductionRequestRaised ? "YES" : "NO" },
            { key: "deduction-request-amount", label: "DEDUCTION REQUEST AMOUNT", value: deductionRequestedAmount ?? "-" },
            // { key: "emi-deducted", label: "EMI DEDUCTED", value: emiDeducted??"-" },
            // { key: "emi-uploaded", label: "EMI UPLOADED", value: emiUploaded??"-"},
            // { key: "processed-date", label: "PROCESSED DATE", value: convertTimestamptoDate(processedDate) },
            { key: "request-date", label: "REQUEST DATE", value: convertTimestamptoDate(requestDate) },
        ]
    }

    createDisbursementDetail = (dat) => {

        if (!dat) return [];

        const { disbursalSentOn, disbursalAmount, transactionDetail, amountCreditedDate, status } = dat;
        return [
            { key: "disbursal-sent-on", label: "DISBURSAL SENT ON", value: convertTimestamptoDate(disbursalSentOn) },
            { key: "disbursal-amount", label: "DISBURSAL AMOUNT", value: disbursalAmount ?? "-" },
            { key: "transaction-detail", label: "TRANSACTION DETAIL", value: transactionDetail ?? "-" },
            { key: "amount-creditd-date", label: "AMOUNT CREDITED DATE", value: convertTimestamptoDate(amountCreditedDate) },
            { key: "status-detail", label: "STATUS", value: status ?? "-" },
        ]
    }

    createLoanApplicationDetail = (dat) => {

        if (!dat) return [];

        const { accountCreationDate, firstLogin, lastLogin, applicationCompletionDate, documentUploadDate, tncAcceptDate } = dat;
        return [
            { key: "account-create-date", label: "ACCOUNT CREATION DATE", value: convertTimestamptoDate(accountCreationDate) },
            // { key: "first-login", label: "FIRST LOGIN", value: convertTimestamptoDate(firstLogin) },
            // { key: "last-login", label: "LAST LOGIN", value: convertTimestamptoDate(lastLogin) },
            { key: "app-complete-date", label: "APPLICATION COMPLETION DATE", value: convertTimestamptoDate(applicationCompletionDate) },
            { key: "doc-upload-date", label: "DOCUMENTS UPLOAD DATE", value: convertTimestamptoDate(documentUploadDate) },
            { key: "tnc-accept-date", label: "T&C ACCEPT DATE", value: convertTimestamptoDate(tncAcceptDate) }
        ]
    }



    createLoanDetail = (dat) => {

        if (!dat) return [];

        const { loanId, loanStatus, loanType, appliedLoanAmount, approvedLoanAmount, outstandingPrinciple, reasonCode, dpdCount, lender, outstandingAmount, bankTransfer,
            collectionMode, statementCycle,
            processingFee, rateOfInterest, tenure, loanCompletionDate, nameVerificationDocUploadDate, manualCheckLastAction, loanCancellationAgent, loanCancellationComment,
            disbursing, reasonCodeMsg, dob, fatherName, lenderChange, lenderChangeDate, isKycComplete,
            kycCompleteDate, riskDecisionDate, isBankVerified, nbfcStatus, manualBankVerificationStatus, emiAmount, dueAmount, overDue, lenderLoanId, interest, interestPaid
        } = dat;
        return [
            { key: "loan-id", label: "LOAN ID", value: loanId },
            { key: "loan-status", label: "LOAN STATUS", value: loanStatus },
            { key: "loan-type", label: "LOAN TYPE", value: loanType },
            { key: "applied-loan-amount", label: "APPLIED LOAN AMOUNT", value: appliedLoanAmount },
            { key: "approved-loan-amount", label: "APPROVED LOAN AMOUNT", value: approvedLoanAmount },
            { key: "outstanding-amount", label: "OUTSTANDING AMOUNT", value: outstandingAmount },
            { key: "outstanding-principal", label: "OUTSTANDING PRINCIPAL", value: outstandingPrinciple },

            { key: "dpd-count", label: "DPD COUNT", value: dpdCount },
            { key: "lender", label: "LENDER", value: lender },
            { key: "l-dob", label: "DOB", value: dob },
            { key: "l-father-name", label: "FATHER NAME", value: fatherName },
            { key: "bank-transfer", label: "BANK TRANSFER", value: bankTransfer ? 'YES' : 'NO' },
            { key: "collection-mode", label: "COLLECTION MODE", value: collectionMode ?? "-" },
            { key: "statement-cycle", label: "STATEMENT CYCLE", value: statementCycle ?? "-" },
            { key: "loan-processing-fee", label: "PROCESSING FEE", value: processingFee },
            { key: "loan-rate-of-interest", label: "RATE OF INTEREST", value: rateOfInterest },
            { key: "loan-tenure", label: "TENURE", value: tenure + " Weeks" },
            { key: "loan-completion-date", label: "COMPLETION DATE", value: convertTimestamptoDate(loanCompletionDate) },
            { key: "loan-name-verification-doc-upload-date", label: "DOC UPLOAD DATE", value: convertTimestamptoDate(nameVerificationDocUploadDate) },
            { key: "loan-manual-check-last-action", label: "MANUAL CHECK LAST ACTION", value: manualCheckLastAction ?? "-" },
            { key: "loan-cancelation-agent", label: "CANCELLED BY", value: loanCancellationAgent ?? "-" },
            { key: "loan-cancelation-comment", label: "CANCELLED COMMENT", value: loanCancellationComment ?? "-" },
            { key: "loan-disbursing", label: "DISBURSING", value: disbursing ? "YES" : "NO" },
            { key: "reason-code", label: "REASON CODE", value: reasonCode },
            { key: "loan-reason-code-msg", label: "REASON CODE MESSAGE", value: reasonCodeMsg ?? "-" },
            { key: "loan-nbfc-status", label: "LENDER STATUS", value: nbfcStatus ?? "-" },
            { key: "loan-lender-change", label: "LENDER CHANGE", value: lenderChange ? "YES" : "NO" },
            { key: "loan-lender-change-date", label: "LENDER CHANGE DATE", value: convertTimestamptoDate(lenderChangeDate) },

            { key: "loan-emi-amount", label: "EMI AMOUNT", value: emiAmount ?? "-" },
            { key: "loan-due-amount", label: "DUE AMOUNT", value: dueAmount ?? "-" },
            { key: "loan-overdue", label: "LOAN OVERDUE", value: overDue ?? "-" },
            { key: "loan-lender-loan-id", label: "LENDER LOAN ID", value: lenderLoanId ?? "-" },
            { key: "loan-interest", label: "INTEREST", value: interest ?? "-" },
            { key: "loan-interest-paid", label: "INTEREST PAID", value: interestPaid ?? "-" },

            { key: "loan-is-kyc-complete", label: "KYC COMPLETE", value: isKycComplete ? "YES" : "NO" },
            { key: "loan-kyc-complete-date", label: "KYC COMPLETE DATE", value: convertTimestamptoDate(kycCompleteDate) },
            { key: "loan-risk-decision-date", label: "SOFT APPROVAL DATE", value: convertTimestamptoDate(riskDecisionDate) },
            { key: "loan-is-bank-verified", label: "BANK VERIFIED", value: isBankVerified ? "YES" : "NO" },
            { key: "loan-manual-bank-verification-status", label: "MANUAL BANK VERIFICATION", value: manualBankVerificationStatus ?? "-" },
        ]
    }

    createLoanUpiAutoPayDetail = (dat) => {

        if (!dat) return [];

        const { allowAutoPay, mandateActive
        } = dat;
        return [
            { key: "l-upi-autopay-allow", label: "IS UPI AUTOPAY ALLOW", value: allowAutoPay ? "YES" : "NO" },
            { key: "l-mandate-allow", label: "IS MANDATE ACTIVE", value: mandateActive ? "YES" : "NO" },

        ]
    }

    createUserDetail = (dat) => {

        if (!dat) return [];

        const { phone, company, name, alternatePhone, userId, nameOnPan } = dat;
        return [
            { key: "name", label: "NAME", value: name },
            { key: "phone", label: "PHONE", value: phone },
            { key: "company", label: "COMPANY", value: company },
            { key: "alternatePhone", label: "ALTERNATE PHONE", value: alternatePhone },
            { key: "l-name-on-pan", label: "NAME ON PAN", value: nameOnPan },
            { key: "l-userId", label: "USER ID", value: userId }
        ]
    }

    createKycDetail = (dat) => {
        if (!dat) return [];
        const { photo, panCard, aadharFront, aadharBack, isCkycEnabled, dobOnAadhaar, ckycStatus, aadhaarFromDigilocker } = dat;
        return [
            { key: "photo", label: "PHOTO", value: photo },
            { key: "panCard", label: "PANCARD", value: panCard },
            // { key: "aadharFront", label: "AADHAR FRONT", value: aadharFront },
            // { key: "aadharBack", label: "AADHAR BACK", value: aadharBack },
            { key: 'kyc_type', label: "KYC TYPE", value: aadhaarFromDigilocker ? "DIGILOCKER" : (isCkycEnabled ? "CKYC" : "-") },
            { key: "icCKYCEnabled", label: "CKYC ENABLED", value: isCkycEnabled ? "Yes" : "No" },
            { key: "dobOnAadhaar", label: "DOB ON AADHAAR", value: dobOnAadhaar ?? "-" },
            { key: "ckycStatus", label: "CKYC STATUS", value: ckycStatus ?? "-" },
            { key: "aadhaarFromDigilocker", label: "AADHAAR FROM DIGILOCKER", value: aadhaarFromDigilocker ? "Yes" : "No" },
        ]
    }

    createDigiLockerDetail = (dat) => {
        if (!dat) return [];
        const { nameOnPan, pan, aadhaar, dob } = dat;
        return [
            { key: "digi-name-on-pan", label: "NAME ON PAN", value: nameOnPan },
            { key: "digi-pan", label: "PAN", value: pan },
            { key: "digi-aadhar", label: "AADHAAR", value: aadhaar },
            { key: "digi-dob", label: "DOB", value: dob }
        ]
    }


    createDigilockerAadhaarDetail = (dat) => {
        if (!dat) return [];
        const { imagePath } = dat;
        return [
            { key: "loan-digilocker-aadhaar-detail", label: "", value: imagePath }
        ]
    }

    createLoanCkycDetail = (dat) => {
        if (!dat) return [];
        const { ckycDocumentUrl } = dat;
        return [
            { key: "loan-ckyc-document-detail", label: "", value: ckycDocumentUrl }
        ]
    }


    createPaymentDetail = () => {
        const { loanStatus } = this.state.loanDetails;
        return [
            { key: "loan-payment-detail", label: "PAYMENT", value: 'View', fn: (v) => v },
            { key: "loan-statement-detail", label: "STATEMENT", value: 'View', fn: (v) => v },
            { key: "all-loan-payment-detail", label: "ALL PAYMENT", value: 'View', fn: (v) => v },
            { key: "all-loan-deduction-detail", label: "DEDUCTION DETAILS", value: 'View', fn: (v) => v },
            loanStatus.toUpperCase() === 'ACTIVE' && { key: "update-payment", label: "UPDATE PAYMENT", value: <UpdatePaymentButton loanId={this.state.id} />, fn: (v) => v }
        ]
    }

    generatePaymentLink = () => {
        const { role } = getLocalStorage("LmsUserDetails");
        const { loanStatus } = this.state.loanDetails;


        const checkPermission = (PERMISSIONS) => {
            let conditionCheck = false;
            for (let item of PERMISSIONS) {
                if (role.includes(item)) {
                    conditionCheck = true;
                    break;
                }
            }

            return conditionCheck;
        }

        if (checkPermission(["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_CE", "ROLE_ADMIN_RO"])) {
            if (loanStatus === "ACTIVE") {
                return [
                    { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
                    { key: "promise-to-pay-loan", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v },
                    // { key: "raise-loan-settlement", label: "RAISE LOAN SETTLEMENT", value: 'Raise', fn: (v) => v }
                ]
            } else {
                return [
                    { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
                    { key: "promise-to-pay-loan", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v }
                ]
            }

        } else {
            return [
                { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
            ]
        }

    }

    createEmandateCharges = () => {
        return [
            { key: "emandate-charge-history", label: "EMANDATE CHARGES", value: 'View', fn: (v) => v }
        ]
    }


    createLoanCancel = (cancelable, allowCancellation) => {
        const ar = [];
        if (cancelable) {
            ar.push({ key: "loan-ni-button", label: "MARK NOT INTERESTED", value: 'Not Interested', fn: (v) => v });
        } if (allowCancellation) {
            ar.push({ key: "loan-cancel-button", label: "LOAN CANCEL", value: 'Cancel', fn: (v) => v });
        }
        return ar;
    }

    checkViewPermission = (key) => {
        const role = "roleAdmin";
        return rolePermission[role].includes(key);
    }


    checkLoanCancelation = () => {
        const blocked = [
            "prashant.kumar@betterplace.co.in",
            "md.azad@betterplace.co.in",
            "chandan.debnath@betterplace.co.in",
            "deepak.jamwal@betterplace.co.in",
            "kajal.thakur@betterplace.co.in",
            "manikant.giri@betterplace.co.in",
            "neelam.saroj@betterplace.co.in",
            "priyanka.shrivastava@betterplace.co.in",
            "sumit.kumar1@betterplace.co.in",
            "roshan.sharma@betterplace.co.in",
            "shalini.lodhi@betterplace.co.in",
            "pratima.pandey@betterplace.co.in",
            "waseem.khan@betterplace.co.in",
            "pooja.saxena@betterplace.co.in",
            "nikita.jain@betterplace.co.in",
            "saisuma@betterplace.co.in",
            "saritha@betterplace.co.in",
            "shilpa.s@betterplace.co.in",
            "divya.t@betterplace.co.in"
        ]
        const res = getLocalStorage('LmsUserDetails');
        const { role, email } = res;
        return !blocked.includes(email);
        // for (let item of role) {
        //     if (["ROLE_LC", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"].includes(item)) {
        //         hasTrue = true;
        //         break;
        //     }
        // }
        // return hasTrue;
    }


    makeColumns = () => {
        const { loanDetails, loanUserDetails, loanApplicationDetails, loanDisbursementDetails, deductionDetails, loanBankDetails,
            loanEmandateDetails, loanKycDetails, loanDigiLockerDetails, loanUpiAutoPayDetails, loanCkycDetails } = this.state;
        const { cancelable, bankTransfer, allowCancellation } = loanDetails;
        const detailData = [
            { key: "user-detail", label: loanUserDetails ? "USER DETAIL" : "", value: this.createUserDetail(loanUserDetails), viewPermission: this.checkViewPermission("user-detail") },
            { key: "loan-detail", label: loanDetails ? "LOAN DETAIL" : "", value: this.createLoanDetail(loanDetails), viewPermission: this.checkViewPermission("loan-detail") },
            { key: "loan-application-detail", label: loanApplicationDetails ? "LOAN APPLICATION DETAIL" : "", value: this.createLoanApplicationDetail(loanApplicationDetails), viewPermission: this.checkViewPermission("loan-application-detail") },
            { key: "disbursement-detail", label: loanDisbursementDetails ? "DISBURSEMENT DETAIL" : "", value: this.createDisbursementDetail(loanDisbursementDetails), viewPermission: this.checkViewPermission("disbursement-detail") },
            { key: "loan-deduction-detail", label: deductionDetails ? "DEDUCTION DETAIL" : "", value: this.createDeductionDetail(deductionDetails), viewPermission: true },
            { key: "bank-detail", label: loanBankDetails ? "BANK DETAIL" : "", value: this.createBankDetail(loanBankDetails), viewPermission: this.checkViewPermission("bank-detail") },
            { key: "emandate-detail", label: loanEmandateDetails ? "EMANDATE DETAIL" : "", value: this.createEmandateDetail(loanEmandateDetails), viewPermission: this.checkViewPermission("emandate-detail") },
            // { key: "loan-upi-auto-pay-col", label: loanUpiAutoPayDetails ? "UPI AUTOPAY DETAIL" : "", value: this.createLoanUpiAutoPayDetail(loanUpiAutoPayDetails), viewPermission: true },
            { key: "loan-kyc-detail", label: loanKycDetails ? "KYC DETAIL" : "", value: this.createKycDetail(loanKycDetails), viewPermission: this.checkViewPermission("loan-kyc-detail") },
            { key: "loan-digi-locker-detail", label: loanDigiLockerDetails ? "DIGILOCKER DETAIL" : "", value: this.createDigiLockerDetail(loanDigiLockerDetails), viewPermission: true },
            { key: "loan-digilocker-aadhaar-col", label: loanDigiLockerDetails ? "DIGILOCKER AADHAAR" : "", value: this.createDigilockerAadhaarDetail(loanDigiLockerDetails), viewPermission: true },
            { key: "loan-ckyc-document-col", label: loanCkycDetails ? "CKYC DETAILS" : "", value: this.createLoanCkycDetail(loanCkycDetails), viewPermission: true },
            { key: "loan-emandate-charges", label: "EMANDATE CHARGES", value: this.createEmandateCharges(), viewPermission: this.checkViewPermission("loan-emandate-charges") },
            { key: "view-payment-detail", label: "PAYMENT DETAIL", value: this.createPaymentDetail(), viewPermission: this.checkViewPermission("view-payment-detail") },
            { key: "generate-payment-link", label: "GENERATE", value: this.generatePaymentLink(), viewPermission: this.checkViewPermission("generate-payment-link") && !bankTransfer },
        ]
        if (cancelable || allowCancellation) detailData.push({ key: "cancel-ni-loan", label: "MARK LOAN AS", value: this.createLoanCancel(cancelable, allowCancellation), viewPermission: this.checkLoanCancelation("cancel-ni-loan") });
        this.setState({ data: detailData })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    render () {
        const { loading, data, id, loanDetails } = this.state;
        const { bankTransfer } = loanDetails;
        return (
            <Detail
                data={data}
                loading={loading}
                handleBack={this.handleBack}
                id={id}
                bankTransfer={bankTransfer}
            />
        );
    }
}

export default LoanDetail;